import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { InterviewReminderService } from '@FeatureUi/InterviewGuideReminders/InterviewGuideReminders.service';
import {
    AuthenticationService,
    DemoAccountService,
    DemoAccountSignalRNotificationData,
    DemoAccountSignalRNotificationDataMessage,
    FeatureFlags,
    FeatureFlagsService,
    InitDataService,
    ModuleAccessService,
    PendoTracking,
    PI2UpgradeStatus,
    PI_VERSION,
    PlatformBootstrapHelperService,
    RouteData,
    SignalRService,
    TranslationService,
    UserProfileSettingsService,
    Util
} from '@predictiveindex/client-core';
import { TapestrySidenavService } from '@predictiveindex/tapestry';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pimono-root',
    host: {
        '[class.pi-leftMenuHidden]': 'minimizeSidebar',
        '[class.pi-leftMenuRemoved]': 'routeData.hideFooter()',
        '[class.browser--edge]': 'util.userAgentDetect.isEdge()',
        '[class.browser--ie11]': 'util.userAgentDetect.isIE11()'
    },
    template: `
        <ng-container *ngIf="platformBootstrapHelper.hasNavigated">
            <tapestry-sidenav *ngIf="contentReady">
                <div class="page-container">
                    <pi-universal-nav
                        #header
                        [disableSearch]="disableSearch"
                    ></pi-universal-nav>
                    <div
                        class="piApp--Content pageWidthLimiter"
                        #mainSection
                        [ngClass]="{
                            hasCognitiveBanner: showCognitiveBanner()
                        }"
                    >
                        <div
                            class="cognitiveBanner"
                            [hidden]="!showCognitiveBanner()"
                        >
                            <span class="cognitiveBanner-bannerText">{{
                                TR.key('banner.cognitiveHidden')
                            }}</span>
                        </div>
                        <upgrade-failure-banner
                            *ngIf="showUpgradeFailureBanner"
                            [(showBanner)]="displayBanner"
                        ></upgrade-failure-banner>
                        <router-outlet></router-outlet>
                    </div>
                    <footer *ngIf="!routeData.hideFooter()">
                        <pi-copyright-footer
                            class="pageWidthLimiter piFooterNew"
                        ></pi-copyright-footer>
                    </footer>
                </div>
            </tapestry-sidenav>
            <ng-container *ngIf="contentReady">
                <peek-root></peek-root>
                <router-outlet name="modalOutlet"></router-outlet>
                <global-modal></global-modal>
                <screen-block></screen-block>
            </ng-container>
        </ng-container>
    `
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('header', { static: false })
    header: ElementRef;
    contentHeight: number;
    // Default to having sidebar closed for mobile
    minimizeSidebar: boolean = true;
    private routerSubscription: Subscription;

    PI_VERSION = PI_VERSION;
    flagsEnabled: string;
    contentReady = false;
    disableSearch = false;
    showUpgradeFailureBanner = false;
    displayBanner = true;

    constructor(
        public TR: TranslationService,
        private router: Router,
        public auth: AuthenticationService,
        private userProfileService: UserProfileSettingsService,
        private reminderService: InterviewReminderService,
        public routeData: RouteData,
        public featureFlags: FeatureFlagsService,
        public moduleAccess: ModuleAccessService,
        public util: Util,
        pendo: PendoTracking,
        private tapestrySideNavService: TapestrySidenavService,
        public platformBootstrapHelper: PlatformBootstrapHelperService,
        private readonly initDataService: InitDataService,
        private readonly signalr: SignalRService,
        private readonly demoAccount: DemoAccountService
    ) {
        pendo.updatePendoMetadata();
        this.setDisableSearch();
        this.setUpgradeFailureBanner();
    }

    async ngOnInit() {
        await this.setupDemoAccountSocketConnection();
    }

    ngAfterViewInit() {
        this.removeLoadingIndicator();
        this.onResize();

        // On route change close the left menu
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.minimizeSidebar = true;
            }
        });

        this.reminderService.start();
        this.flagsEnabled = this.featureFlags.featureFlagValues();
        this.tapestrySideNavService.closeIconAltText = this.TR.key(
            'label.common-terms.close'
        );
        this.contentReady = true;
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }

    showCognitiveBanner() {
        return (
            this.userProfileService.isCognitiveEnabled() &&
            !this.userProfileService.showCognitive
        );
    }

    getViewportValue(): string {
        return 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';
    }

    /**
     * Removes the loading indicator off the root of the page
     */
    removeLoadingIndicator() {
        // NOTE: classlist only supported after IE10
        document.body.classList.remove('spinner');
    }

    // TODO - can we directly bind this and not catch any resize events? Angular binding
    // probably wouldn't happen without event (similar to scroll)
    @HostListener('window:resize')
    onResize() {
        if (
            this.header &&
            this.header.nativeElement &&
            this.header.nativeElement.offsetHeight
        ) {
            const headerHeight = this.header.nativeElement.offsetHeight;
            const windowHeight = window.innerHeight;
            this.contentHeight = Math.max(windowHeight - headerHeight, 600);
        }
    }

    private setDisableSearch() {
        if (
            !this.featureFlags.getByFlag(
                FeatureFlags.enableUpgradeInProgressPage
            )
        ) {
            return;
        }

        const upgradeStatus = this.parseUpgradeStatus(
            this.initDataService.account?.pi2UpgradeStatus ?? 'Unknown'
        );
        this.disableSearch =
            upgradeStatus === PI2UpgradeStatus.UpgradeInProgress ||
            upgradeStatus === PI2UpgradeStatus.UpgradeFailure;
    }

    private parseUpgradeStatus(upgradeStatus: string): PI2UpgradeStatus {
        return Object.values(PI2UpgradeStatus).includes(
            upgradeStatus as PI2UpgradeStatus
        )
            ? (upgradeStatus as PI2UpgradeStatus)
            : PI2UpgradeStatus.Unknown;
    }

    private setUpgradeFailureBanner() {
        const upgradeStatus = this.parseUpgradeStatus(
            this.initDataService.account?.pi2UpgradeStatus ?? 'Unknown'
        );

        this.showUpgradeFailureBanner =
            upgradeStatus === PI2UpgradeStatus.UpgradeFailureRollback &&
            this.featureFlags.getByFlag(
                FeatureFlags.enableUpgradeInProgressPage
            ) &&
            this.displayBanner;
    }

    /**
     * Establishes a socket connection to the demo account service
     *
     * @private
     */
    private async setupDemoAccountSocketConnection() {
        await this.signalr.connect();
        const socketMessages = this.signalr.listenFor<
            DemoAccountSignalRNotificationDataMessage,
            DemoAccountSignalRNotificationData
        >(DemoAccountSignalRNotificationDataMessage);

        socketMessages.subscribe(
            async (message) =>
                await this.demoAccount.processNotificationQueue(message)
        );
    }
}
